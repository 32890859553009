<script>
import { fakeData } from "./fakeData";
import DatePicker from "vue2-datepicker";
// <date-picker v-model="defaultdate" :first-day-of-week="1" lang="en" confirm></date-picker>

export default {
  page: {
    title: "Financial Table",
  },
  components: {
    DatePicker
  },
  data() {
    return {
      tableData: [],
      fakeData,
      title: "Advanced",
      items: [
        {
          text: "Tables",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 20, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          key: "serial_no",
          sortable: true,
        },
        {
          key: "date_time",
          sortable: false,
        },
        {
          key: "type",
          sortable: false,
        },
        // {
        //   key: "category",
        //   sortable: false,
        // },

        {
          key: "bill_name",
          sortable: false,
        },
        {
          key: "amount",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.setTableData();
  },
  methods: {

    useOpenDeletePopup(id, index) {
      this.openDeletePopup(id, index);
    },
    setTableData() {
      this.tableData = this.fakeData.map((item) => ({
        ...item,
        isEdit: false,
      }));
    },
    editRowHandler(data) {
      this.financialTableData[data.index].isEdit = !this.financialTableData[data.index].isEdit;
      console.log(data);
    },
  },
  props: ["openDeletePopup", "financialTableData", "setPageNumber", "totalCount"],
  middleware: "authentication",
  watch:{
    currentPage(val){
      this.setPageNumber(val);
    }
  }
};
</script>

<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">List of Finances</h4>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <!-- <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label> -->
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="financialTableData ? financialTableData : []"
              :fields="fields"
              responsive="sm"
              :per-page="0"
              :current-page="currentPage"
            >
              <template #cell(date_time)="data">
                <date-picker
                 v-model="financialTableData[data.index].date_time" 
                 :first-day-of-week="1" 
                 lang="en" 
                 v-if="financialTableData[data.index].isEdit"
                 ></date-picker>
                <!-- <b-form-input v-if="financialTableData[data.index].isEdit" v-model="financialTableData[data.index].date_time" class="form-control"></b-form-input> -->
                <span v-else>{{ data.value.toLocaleString().split(',')[0] }}</span>
              </template>
              <template #cell(type)="data">
                <b-form-select
                  v-if="financialTableData[data.index].isEdit"
                  class="form-control"
                  :options="['Expense', 'Collection']"
                  type="text"
                  v-model="financialTableData[data.index].type"
                ></b-form-select>
                <span v-else>{{ data.value }}</span>
              </template>
              <template #cell(category)="data">
                <b-form-select
                  v-if="financialTableData[data.index].isEdit"
                  v-model="financialTableData[data.index].category"
                  :options="['Employee Pay']"
                  class="form-control"
                ></b-form-select>
                <span v-else>{{ data.value }}</span>
              </template>
              <template #cell(bill_name)="data">
                <b-form-input
                  v-if="financialTableData[data.index].isEdit"
                  v-model="financialTableData[data.index].bill_name"
                  class="form-control"
                ></b-form-input>
                <span v-else>{{ data.value }}</span>
              </template>
              <template #cell(amount)="data">
                <b-form-input
                  v-if="financialTableData[data.index].isEdit"
                  v-model="financialTableData[data.index].amount"
                  class="form-control"
                ></b-form-input>
                <span v-else>₹{{ data.value }}</span>
              </template>

              <template #cell(actions)="data">
                <div>
                  <i
                    v-if="!financialTableData[data.index].isEdit"
                    @click="editRowHandler(data)"
                    class="fas fa-pencil-alt"
                    style="color: #5b73e8; cursor: pointer"
                  ></i>
                  <i
                    v-else
                    class="far fa-check-circle"
                    @click="editRowHandler(data)"
                    style="color: #5b73e8; cursor: pointer"
                  ></i>
                  <i
                    class="fas fa-trash-alt"
                    style="color: #f46a6a; cursor: pointer; margin-left: 1rem"
                    @click="useOpenDeletePopup(data.item.id, 1)"
                  ></i>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalCount"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
