export const fakeData = [
    {
        id: 1,
        serial_no:1,
        date_time:'2022-09-01T15:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },
    {
        id: 2,
        serial_no:2,
        date_time:'2022-09-01T16:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },
    {
        id: 3,
        serial_no:3,
        date_time:'2022-09-01T17:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },
    {
        id: 4,
        serial_no:4,
        date_time:'2022-09-01T17:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },
    {
        id: 4,
        serial_no:4,
        date_time:'2022-09-01T18:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },
    {
        id: 5,
        serial_no:5,
        date_time:'2022-09-01T19:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },
    {
        id: 6,
        serial_no:6,
        date_time:'2022-08-01T6:29:56',
        type:'Expense',
        category:'Employee Pay',
        bill_name:'Connie Franco',
        amount:'6000',
    },


    
]