<template>
  <Home>
    <Preloader v-if="loader" />
    <div v-if="!isEmpty">
      <div class="special-class-resp d-flex justify-content-between align-items-center mb-3">
        <div>
        <b-button v-b-modal.modal-1 type="button" class="btn btn-info">
        <span>+</span> Add Collections
      </b-button>
      <b-button v-b-modal.modal-2 type="button" class="btn btn-pr">
        <span>+</span> Add Expenses
      </b-button>
      </div>
      <div class="mb-3">
        <div class="d-flex d-md-block justify-content-center">
          <input type="date" id="date1" class="btn custom-date" v-model="startingDate" />
          <input type="date" id="date2" class="btn custom-date" v-model="endingDate" />
        </div>
      </div>
      </div>
      <div>
         <img src="@/assets/Filter.png" />
        <button type="button" class="btn-exp">
          <a href="https://prod.rentnet.in/financial/export/" download
            ><span><img src="@/assets/download-alt.png"/></span>Export</a
          >
        </button>
      </div>
      <div class="w-100">
        <div class="d-flex flex-column justify-content-start position-relative">
          <FinancialTable
            :setPageNumber="setPageNumber"
            :financialTableData="financialTableData"
            :pageNumber="pageNumber"
            :totalCount="totalCount"
          />
        </div>
        <b-modal
          class="main-modal"
          id="modal-1"
          title="Add Collections"
          ok-title="Confirm"
          @ok="addCol"
        >
          <div class="modal-ele">
            <div>
              <label><strong>Building Name</strong></label>
              <multiselect
                v-model="selectedBuilding"
                :options="buildingsData"
                track-by="id"
                label="name"
                class="helo"
              ></multiselect>
            </div>
            <div>
              <label for="tenant"><strong>Tenant</strong></label>
              <multiselect
                v-model="tenants"
                :options="tenant_items"
                track-by="id"
                label="name"
                class="helo"
              >
              </multiselect>
            </div>
            <div>
              <label for="tenant"><strong>Payment Method</strong></label>
              <multiselect
                v-model="payment_method"
                :options="methodOptions"
                track-by="id"
                label="name"
                class="helo"
              >
              </multiselect>
            </div>

            <div>
              <label for="amount"><strong>Amount</strong></label>
              <input
                type="number"
                class="form-control"
                placeholder="₹ Enter Amount "
                v-model="payment_amount"
              />
            </div>
            <div>
              <label for="amount"><strong>Finance By</strong></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter name"
                v-model="finance_by"
              />
            </div>
            <div>
              <label for="finance_name"><strong>Finance Name</strong></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Description"
                v-model="finance_name"
              />
            </div>
            <div>
              <label for="date"><strong>Date</strong></label>
              <date-picker
                v-model="date"
                :first-day-of-week="1"
                lang="en"
              ></date-picker>
            </div>
          </div>
        </b-modal>

        <b-modal
          class="main-modal"
          id="modal-2"
          title="Add Expenses"
          ok-title="Confirm"
          @ok="addExp"
        >
          <div class="modal-ele">
            <div>
              <label><strong>Building Name</strong></label>
              <multiselect
                v-model="selectedBuilding"
                :options="buildingsData"
                track-by="id"
                label="name"
                class="helo"
              ></multiselect>
            </div>
            <div>
              <label for="category"><strong>Category</strong></label>
              <multiselect
                v-model="expense_category"
                :options="options"
                track-by="id"
                label="name"
                class="helo"
              ></multiselect>
            </div>
            <div>
              <label for="tenant"><strong>Payment Method</strong></label>
              <multiselect
                v-model="payment_method"
                :options="methodOptions"
                track-by="id"
                label="name"
                class="helo"
              >
              </multiselect>
            </div>
            <div>
              <label for="tenant"><strong>Expense Frequency</strong></label>
              <multiselect
                v-model="expense_frequency"
                :options="frequencyOptions"
                track-by="id"
                label="name"
                class="helo"
              >
              </multiselect>
            </div>
            <!-- <button v-b-modal.modal-3 class="btn-exp">
              <span>+</span>add custom
            </button> -->
            <div class="d-flex justify-content-start flex-column">
              <label for="invoice"><strong>Upload Invoice</strong></label>
              <input type="file" name="invoice" id="invoice" />
            </div>
            <div>
              <label for="amount"><strong>Finance By</strong></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter person name"
                v-model="finance_by"
              />
            </div>
            <div>
              <label for="amount"><strong>Finance Name</strong></label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Name "
                v-model="finance_name"
              />
            </div>
            
            <div>
              <label for="date"><strong>Date</strong></label>
              <date-picker
                v-model="date"
                :first-day-of-week="1"
                lang="en"
                confirm
              ></date-picker>
            </div>
            <div>
              <label for="notes"><strong>Notes</strong></label>
              <textarea
                name="notes"
                id=""
                rows="4"
                placeholder="Enter any notes you wish to add"
                class="w-100 form-control"
                v-model="notes"
              ></textarea>
            </div>
          </div>
        </b-modal>

        <b-modal
          id="modal-3"
          title="Create Custom Category"
          ok-title="Save"
          @ok="appendo"
        >
          <div class="modal-ele">
            <label for="create"><strong>Enter Category Name</strong></label>
            <input
              type="text"
              id="create"
              class="form-control"
              placeholder="Enter name here"
              v-model="option"
            />
          </div>
        </b-modal>
      </div>
    </div>
    <div v-if="isEmpty">
      <div style="height:80vh;" class="d-flex flex-column align-items-center">
        <h5>No Financials Found</h5>
      </div>
    </div>
  </Home>
</template>
<script>
import FinancialTable from "@/new-components/financial-table/FinancialTable.vue";
import Home from "@/new-views/Home/Home.vue";
import { getFinance, getCategories, getallbuilding } from "@/utils/apis.js";
import { createCollection } from "@/utils/apis.js";
import { createExp } from "@/utils/apis.js";
// import { expFinance } from "@/utils/apis.js";
import { getAllTenants } from "@/utils/apis.js";
import Preloader from "@/new-components/Preloader/Preloader.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import axios from "axios";
Vue.use(axios);
export default {
  data() {
    return {
      date: "",
      expense_category: [],
      tenants: [],
      amount: "",
      payment_amount: "",
      files: [],
      finance_name: "",
      file: null,
      tenant_items: [],
      selectedBuilding: [],
      options: [
        "Recurring MoM",
        "Category A",
        "Category B",
        "Category C",
        "Category D",
        "Category E",
        "Category F",
        "Category G",
        "Category H",
      ],
      loader: true,
      option: [],
      // addCollections: false,
      financialTableData: [],
      startingDate: "",
      endingDate: "",
      resultobj: [],
      isEmpty: false,
      buildingsData: [],
      methodOptions: [
        { id: 1, name: "Cash" },
        { id: 2, name: "Card" },
        { id: 3, name: "Online ( UPI/Netbanking )" },
      ],
      expense_frequency: [],
      frequencyOptions: [
        {
          id: 1,
          name: "Recurring MoM",
        },
        {
          id: 2,
          name: "Recurring YoY",
        },
        {
          id: 3,
          name: "Variable",
        },
      ],
      payment_method: [],
      notes: "",
      pageNumber: 1,
      totalCount: 0,
      finance_by:"",
    };
  },
  methods: {
    sortBetweenDates(data) {
      this.financialTableData = [];
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].date >= this.startingDate &&
          data[i].date <= this.endingDate
        ) {
          this.financialTableData.push({
            id: data[i].id,
            serial_no: data[i].id,
            date_time: data[i].date,
            type: data[i].finance_type,
            category: data[i].expense_category,
            bill_name: data[i].finance_name,
            amount: data[i].amount,
          });
        }
      }
    },
    async useFinancetable() {
      const body = {
        limit: 10,
        offset: (this.pageNumber - 1) * 10,
      };
      const result = await getFinance(body);
      this.loader = false;
      this.totalCount = result.count;
      this.financialTableData = result.results
        ? this.formatTabledata(result.results)
        : [];
    },
    async getTenantName() {
      const response = await getAllTenants();
      console.log(response.results);
      let newData = [];
      response.results.map((item) =>
        newData.push({ id: item.id, name: item.name })
      );
      this.tenant_items = [...newData];
    },
    async addCol() {
      // bvModalEvt.preventDefault();
      const apibody = {
        amount: parseInt(this.payment_amount),
        finance_name: this.finance_name,
        date: this.date.toISOString().split("T")[0],
        // tenants: this.getTenantName,
        finance_type: "Collection",
        finance_by: this.finance_by,
        notes: this.notes,
        collected_for: parseInt(this.tenants.id),
        payment_method: this.payment_method.name,
        building: parseInt(this.selectedBuilding.id),
      };
      let res = await createCollection(apibody);
      console.log(res);
      this.useFinancetable;
      if (
        !this.payment_amount ||
        !this.date ||
        !this.expense_category ||
        !this.tenants ||
        !this.payment_amount ||
        !this.finance_name
      ) {
        this.$toasted.error("Please fill all details", {
          position: "top-center",
          duration: 3000,
        });
      } else {
        if (res.success) {
          this.$toasted.success("Collection saved successfully", {
            position: "top-center",
            duration: 3000,
          });
        } else {
          this.$toasted.error("Garbar hai re baba", {
            position: "top-center",
            duration: 3000,
          });
        }
      }
    },
    async addExp(bvModalEvt) {
      // bvModalEvt.preventDefault();
      console.log(bvModalEvt);
      const body = {
        finance_name: this.finance_name,
        finance_by: this.finance_by,
        amount: this.amount,
        date: this.date.toISOString().split("T")[0],
        expense_category: this.expense_category.name,
        finance_type: "Expense",
        image: document.getElementById("invoice").files[0],
        building: parseInt(this.selectedBuilding.id),
        payment_method: this.payment_method.name,
        expense_frequency: this.expense_frequency.name,
        notes: this.notes,
      };

      if (!this.date || !this.expense_category || !this.finance_name) {
        this.$toasted.error("Please fill all details", {
          position: "top-center",
          duration: 3000,
        });
      } else {
        let result = await createExp(body);
        console.log(result);
        if (result.success) {
          this.$toasted.success(
            "Expenses are saved successfully to finance table",
            {
              position: "top-center",
              duration: 3000,
            }
          );
        } else {
          this.$toasted.error("Data couldn't be sent", {
            position: "top-center",
            duration: 3000,
          });
        }
      }
    },
    async appendo() {
      this.options.push(this.option);
    },
    formatTabledata(data) {
      let array = [];
      data.forEach((element) => {
        array.push({
          id: element.id,
          serial_no: element.id,
          date_time: new Date(element.date + "T00:00:00.000Z"),
          type: element.finance_type,
          category: element.expense_category,
          bill_name: element.finance_name,
          amount: element.amount,
          isEdit: false,
        });
      });
      console.log(array);
      return array;
    },
    async useGetCategories() {
      const response = await getCategories();
      this.options = [...response.data["Expense Categories"]];
    },
    async useGetallBuilding() {
      const response = await getallbuilding();
      response.buildings
        ? (this.buildingsData = [
            ...this.formatbuildingsdata(response.buildings),
          ])
        : [];
      console.log(response);
    },
    formatbuildingsdata(data) {
      let array = [];
      data.forEach((element) => {
        array.push({
          id: element.id,
          name: element.building_name,
          number_of_units: element.no_of_units,
          number_of_tenants: element.owner,
          image: element.image_url,
          total_earnings: element.earnings.amount__sum,
        });
      });
      console.log(array);
      return array;
    },
    setPageNumber(pageNumber) {
      this.pageNumber = pageNumber;
    },
  },
  mounted() {
    this.useFinancetable();
    this.getTenantName();
    this.useGetCategories();
    this.useGetallBuilding();
  },
  components: {
    FinancialTable,
    Home,
    Preloader,
    Multiselect,
    DatePicker,
  },
  watch: {
    startingDate: function() {
      this.sortBetweenDates(this.resultobj);
    },
    endingDate: function() {
      this.sortBetweenDates(this.resultobj);
    },
    pageNumber: function(newVal) {
      console.log(newVal);
      this.useFinancetable();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-pr {
  background-color: white;
  border-color: #4cc9f0;
  color: #4cc9f0;
  margin: 5px;
}
#date1 {
  border-color: rgb(156, 156, 156);
  margin-right: 1rem;
}
#date2 {
  // width: 100px;
  border-color: rgb(156, 156, 156);
  margin-right: 1rem;
}

.btn-exp {
  border-color: transparent;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
}
.modal-ele {
  display: flex;
  flex-direction: column;
}
title {
  font-weight: 100;
}

.main-modal {
  position: absolute;
  width: 613px;
  height: 649px;
  left: 424px;
  top: 125px;

  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.dp {
  width: 50px;
}

@media screen and (max-width: 600px) {
  .special-class-resp{
    flex-direction: column;
  }
  .custom-date{
    width: 35% !important;
  }
}
</style>
